(function ($, generic) {
  Drupal.behaviors.languageSelectorV1 = {
    attach: function (context) {
      var $langContainer = $('.js-language-container', context);
      var $langLink1 = $('.js-lang-link1');
      var $langLink2 = $('.js-lang-link2');
      var cookieLocale = generic.cookie('LOCALE');

      if ($langContainer.length) {
        if ($langContainer.find($langLink1).length) {
          $langContainer.find($langLink1).attr('href', $langContainer.data('link1-url'));
        }
        if ($langContainer.find($langLink2).length) {
          $langContainer.find($langLink2).attr('href', $langContainer.data('link2-url'));
        }
      }
      if (cookieLocale === 'en_US' || cookieLocale === 'en_CA') {
        $langLink1.addClass('lang--active');
      } else {
        $langLink2.addClass('lang--active');
      }
    }
  };
})(jQuery, window.generic || {});
